body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #23a1d1; /* Full blue background */
}

.profile-container {
  display: flex;
  justify-content: center;
  padding-top: 100px;
}

.profile-content {
  padding: 20px;
  width: 400px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.profile-content h1 {
  text-align: center;
  color: #333;
}

.profile-content p {
  color: #666;
  line-height: 1.6;
  margin: 10px 0;
}

.cancel-sub-btn {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: #ff4d4f;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancel-sub-btn:hover {
  background-color: #e33e41;
}
