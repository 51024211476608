@font-face {
  font-family: 'Ralway Bold';
  src: local('Ralway'), url('./assets/fonts/raleway-v29-latin-700.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Ralway Regular';
  src: local('Ralway'), url('./assets/fonts/raleway-v29-latin-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body { 
  font-family: 'Ralway Regular', sans-serif;
  color: #EDEEEE;
  letter-spacing: 0.4px;
  line-height: 1.7;
}

button {
  font-family: 'Ralway Bold', sans-serif;
}