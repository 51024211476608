.form-container {
  max-width: 400px;
  margin: 100px auto 0;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

.form-container h1 {
  text-align: center;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group p {
  color: #d9534f;
  font-size: 0.9rem;
  margin-top: 5px;
}

.form-container button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #0056b3;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.form-container button:hover {
  background-color: #003d82;
}
