.tools {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding-top: 100px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #23a1d1;
  color: #eeeeee;
}

.tools-heading {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
