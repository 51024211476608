.navbar {
  background-color: #23a1d1; /* Background color */
  color: white;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .navbar {
    padding: 5px 15px;
    align-items: center;
  }
}

.navbar__logo {
  text-decoration: none;
  color: white;
  font-size: 1.5rem; 
}

.navbar__logo-img {
  display: block;
  height: 44px;
}

@media screen and (min-width: 768px) {
  .navbar__logo {
    padding-left: 20px;
  }

  .navbar__logo-img {
    height: 65px;
  } 
}

.nav-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.nav-toggle {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 40px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .nav-toggle {
    display: none;
  }
}

.nav-links {
  display: none;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 0;
  padding-right: 10px;
}

.nav-links.active {
  display: flex; 
}

@media screen and (min-width: 768px) {
  .nav-links {
    display: flex;
    flex-direction: row;
  }
}

.nav-item {
  font-family: 'Ralway Bold';
  display: block;
  text-decoration: none;
  padding-top: 10px;
  color: #EDEEEE;
  font-size: 13.8px;
}

.nav-item:hover {
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .nav-item {
    padding: 0 1rem;
  }
}
